import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule),
    },
    {
        path: 'home/:locale',
        loadChildren: () => import('./home/home.module').then((m) => m.HomePageModule),
    },
    {
        path: 'results/:assessment/:locale',
        loadChildren: () => import('./results/results.module').then((m) => m.ResultsPageModule),
    },
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full',
    },
    {
        path: 'sign-up',
        loadChildren: () => import('./sign-up/sign-up.module').then((m) => m.SignUpPageModule),
    },
    {
        path: 'sign-up/:locale',
        loadChildren: () => import('./sign-up/sign-up.module').then((m) => m.SignUpPageModule),
    },
    {
        path: 'policies/:locale',
        loadChildren: () => import('./policies/policies.module').then((m) => m.PoliciesModule),
    },
    {
        path: 'assessment/:assessment/:locale',
        loadChildren: () => import('./assessment/assessment.module').then(m => m.AssessmentPageModule)
    },
    {
        path: 'access-results/:clientId/:locale',
        loadChildren: () => import('./access-results/access-results.module').then((m) => m.AccessResultsPageModule),
    },
    {
        path: 'welcome-back/:clientId/:error/:locale',
        loadChildren: () => import('./welcome-back/welcome-back.module').then((m) => m.WelcomeBackPageModule),
    },
    {
        path: 'email-validation/success/:locale',
        loadChildren: () => import('./email-verification/success/success.module').then((m) => m.SuccessValidationPageModule),
    },
    {
        path: 'email-validation/error/:locale',
        loadChildren: () => import('./email-verification/error/unsuccessful.module').then((m) => m.UnsuccessfulValidationPageModule),
    },
    {
        path: 'email-validation/:locale',
        loadChildren: () => import('./email-verification/check-bot/check-bot.module').then((m) => m.CheckBotPageModule),
    },
    {
        path: 'error/:locale',
        loadChildren: () => import('./error-screen/error-screen.module').then((m) => m.ErrorScreenPageModule),
    },
    {
        path: '**',
        loadChildren: () => import('./error-screen/error-screen.module').then((m) => m.ErrorScreenPageModule),
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
