import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { JwtHelperService } from '@auth0/angular-jwt';
import { concat, Observable } from 'rxjs';
import { flatMap, map, take } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { Client } from '@haleo-frontend/data-access/models';
import { EnvironmentUtils } from '@haleo-frontend/utils';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(private http: HttpClient,
                private storage: Storage,
                private jwtHelperService: JwtHelperService) {
    }

    hasToken(): Observable<boolean> {
        return fromPromise(this.storage.get('access_token'))
            .pipe(map(token => !!token));
    }

    isAuthenticated(): Observable<boolean> {
        return fromPromise(this.storage.get('access_token'))
            .pipe(map(token => token && !this.jwtHelperService.isTokenExpired(token)));
    }

    isCaseManager(): Observable<boolean> {
        return fromPromise(this.storage.get('access_token'))
            .pipe(map(token => {

                if (token && !this.jwtHelperService.isTokenExpired(token)) {
                    const data = this.jwtHelperService.decodeToken(token);
                    return data.scopes.findIndex((scope: string) => scope === 'case-manager') >= 0;
                } else {
                    return false;
                }
            }));
    }

    isScopedToClientAssessments(): Observable<boolean> {
        return fromPromise(this.storage.get('access_token'))
            .pipe(map(token => {

                if (token && !this.jwtHelperService.isTokenExpired(token)) {
                    const data = this.jwtHelperService.decodeToken(token);
                    return data.scopes.findIndex((scope: string) => scope === 'client-assessment') >= 0;
                } else {
                    return false;
                }
            }));
    }

    getScopedClientAssessment(): Observable<string> {
        return fromPromise(this.storage.get('access_token'))
            .pipe(map(token => {

                if (token && !this.jwtHelperService.isTokenExpired(token)) {
                    const data = this.jwtHelperService.decodeToken(token);
                    return data['client-assessment'];
                } else {
                    return null;
                }
            }));
    }

    login(email: string, password: string, scope: string, appKey: string, locale: string): Observable<Client> {
        return this.http.post(EnvironmentUtils.env.api + 'authenticate/login', {email, password, scope, appKey, locale})
            .pipe(flatMap(token => fromPromise(this.storage.set('access_token', token))));
    }

    logout(): Observable<any> {
        return fromPromise(this.storage.keys())
            .pipe(flatMap(keys => concat(keys.map(async key => fromPromise(this.storage.remove(key))))), take(1));
    }

    loginByClientId(clientId: number): Observable<Client> {
        return this.http.post(EnvironmentUtils.env.api + 'authenticate/login-client-id', {clientId, scope: 'screener'})
            .pipe(flatMap(token => fromPromise(this.storage.set('access_token', token))));
    }

    loginByClientIdAndPhoneNumber(clientId: number, phoneNumber: string): Observable<Client> {
        return this.http.post(EnvironmentUtils.env.api + 'authenticate/login-client-id-phone-number', {
            clientId,
            phoneNumber,
            scope: 'screener'
        })
            .pipe(flatMap(token => fromPromise(this.storage.set('access_token', token))));
    }

    loginByClientIdScoped(authData: any): Observable<Client> {
        return this.http.post(EnvironmentUtils.env.api + 'authenticate/login-client-id-scoped', {authData})
            .pipe(flatMap(token => fromPromise(this.storage.set('access_token', token))));
    }

    register(email: string, password: string, locale: string, validationToken: string | null, validationTokenToken: string | null, scope: string, appKey: string, storeNumber: number): Observable<Client> {
        return this.http.post(EnvironmentUtils.env.api + 'authenticate/register', {
            locale,
            email,
            password,
            password_confirmation: password,
            validationToken,
            validationTokenToken,
            scope,
            appKey,
            storeNumber
        })
            .pipe(flatMap(token => fromPromise(this.storage.set('access_token', token))));
    }

    registerWithoutPassword(data: any): Observable<any> {
        return this.http.post(EnvironmentUtils.env.api + 'authenticate/register-without-password', data);
    }

    registerWithoutPasswordNewScreener(data: any): Observable<any> {
        return this.http.post(EnvironmentUtils.env.api + 'authenticate/register-without-password', data)
            .pipe(flatMap(token => fromPromise(this.storage.set('access_token', token))));
    }

    sendResetPasswordLink(email: string, appKey: string, locale: string): Observable<any> {
        return this.http.post(EnvironmentUtils.env.api + 'authenticate/send-reset-password-link', {email, appKey, locale});
    }
}
