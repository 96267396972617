import { environment as base } from '@haleo-frontend/utils';

export const environment = {
    ...base,
    appId: 'ca.haleo.screener-app.prod',
    sentryDSN: 'https://0c5f0cb86920459cabec4f47ef4e0c2c@sentry-relay.haleo.ca/4504323173515264',
    firebaseConfig: {
        apiKey: "AIzaSyDQpMgAsB38ewHrnplgn6WctrfMGcts_Qs",
        authDomain: "haleo-screener-v3.firebaseapp.com",
        projectId: "haleo-screener-v3",
        storageBucket: "haleo-screener-v3.appspot.com",
        messagingSenderId: "511436099896",
        appId: "1:511436099896:web:4eb5932303b479ab1b31cd",
        measurementId: "G-PPRP5S35FB",
    },
    fileReplacements: [
        {
          "replace": "src/index.html",
          "with": "src/index.prod.html"
        }
    ],
    recaptchaSiteKey: '6Lep_0EnAAAAAKvxim2UZcXDQqd_7oUO6oIa1QzJ',
};
