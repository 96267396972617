export const environment = {
    name: 'prod',
    production: true,
    testing: false,
    api: 'https://api.haleo.ca/api/v1/',
    systemLoadApi: 'https://api-status.haleo.ca/api/v1/',
    apiDomain: 'api.haleo.ca',
    resourcesUrl:
        'https://haleo-backend.azureedge.net/storage/backend/resources/',
    stripeKey: 'pk_live_mkdeu8FgpUp2eXcg3HQCeXBp00bEP969ed',
    programKeys: {
        sleepCoachingProgram: 'sleepCoachingProgram',
        cbtiProgram: 'cbtiProgram',
        cbtiShiftWorkProgram: 'cbtiShiftWorkProgram',
        selfGuidedCbtiProgram: 'selfGuidedCbtiProgram',
        cbtiNightmareProgram: 'cbtiNightmareProgram',
        cbtiShiftWorkNightmareProgram: 'cbtiShiftWorkNightmareProgram',
        sleepCoachingNewParentProgram: 'sleepCoachingNewParentProgram',
        cbtiNewParentProgram: 'cbtiNewParentProgram',
    },
    screenerRecommendations: {
        cbtiProgram: 'cbtiProgram',
        cbtiShiftWorkNightmareProgram: 'cbtiShiftWorkNightmareProgram',
        keepGoodWork: 'keepGoodWork',
        sleepCoachingProgram: 'sleepCoachingProgram',
        seeDoctorDontComeBack: 'seeDoctorDontComeBack',
        seeDoctorComeBack: 'seeDoctorComeBack',
        cbtiNightmareProgram: 'cbtiNightmareProgram',
        swsdWaitlist: 'swsdWaitlist',
        cbtiShiftWorkProgram: 'cbtiShiftWorkProgram',
        sleepCoachingProgramNewParent: 'sleepCoachingNewParentProgram',
        cbtiDelayedPhase: 'cbtiDelayedPhase',
    },
    cacheValidity: {
        short: 30000,
    },
    sentryDSN: '',
    amplitudeKey: '',
    firebaseConfig: {
        apiKey: '',
        authDomain: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: '',
        measurementId: '',
    },
    appId: '',
    zoomApiKey: '',
    sleepCountry: { zendeskAccount: '', olark: '' },
    broadcasting: {
        authEndpoint: 'https://api.haleo.ca/broadcasting/auth',
        host: 'broadcast.haleo.ca',
        key: 'mxVz93DWI57KiS9737dy5cB40ILUFK2Y6s7tzdhLHnYpk6euOaxp60dB3PAX',
        port: 443,
        tls: true,
    },
    growthBookKey: '',
    screenerV3Url: 'https://questionnaire.haleoclinic.com/',
};
